import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dropDownItem: {
    width: '100%',
    padding: '0 16px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  dialog: {
    padding: '10px',
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  deleteAccountWrap: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&>svg': {
      transform: 'rotate(-90deg)',
      width: '35px',
      height: '35px',
    },
  },
  section: {
    paddingTop: '20px',
  },
  subHeader: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#545964',
  },
  subtitles: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#545964',
  },
}));

export default useStyles;
