import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  view: {
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
  },
  contentArea: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'rgb(236, 235, 239)',
  },
  paperContainer: {
    boxSizing: 'border-box',
    margin: '1rem',
    marginTop: '1em',
    overflow: 'auto',
    height: '100%',
    padding: '1rem',
    maxHeight: 'calc(100% - 130px)',
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: '250px',
  },
}));

export default useStyles;
