import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import clsx from "clsx";
import { Form, Formik } from "formik";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmail } from "react-multi-email";
import { t } from "ttag";
import * as Yup from "yup";
import { TopBar } from "../../components";
import { languages } from "../../constants/languages";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Arrow as SvgArrow } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ArrowBack, Avatar } from "../../svgComponents";
import styles from "./profile.style";
import { useHistory } from "react-router-dom";

interface IUserInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  username?: string;
  dateFormat?: number;
  language?: string;
  temperatureScale?: number;
  timeFormat?: number;
  [key: string]: any;
  is2FA?: boolean;
}

interface IObject {
  [key: string]: any;
}

const ProfileScreen: React.FC<any> = () => {
  const history = useHistory();

  const types = useStoreState((s) => s.types);
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const displayFlagsMap = useStoreState((state) => state.users.displayFlagsMap);
  const { customerId } = selections;
  const displayFlags = displayFlagsMap[customerId || ""] || {};

  const configFile = useStoreState((s) => s.config);
  const { disable2FA } = configFile || {};

  const { temperatureScale: temperatureScaleTypes, dateFormat: dateFormatTypes, timeFormat: timeFormatTypes } = types;
  const languagesOptions = languages && Object.keys(languages).map((language: any) => <MenuItem value={language} key={`languageOption-${language}`}>{languages[language]}</MenuItem>);
  const temperatureScaleOptions = temperatureScaleTypes && Object.keys(temperatureScaleTypes).map((temperatureScale: any) => { if (+temperatureScale !== 0) return <MenuItem value={temperatureScale} key={`temperatureScaleOption-${temperatureScale}`}>{temperatureScaleTypes[temperatureScale]}</MenuItem>; });
  const dateFormatOptions = dateFormatTypes && Object.values(dateFormatTypes).map((dateFormat: any) => <MenuItem value={dateFormat.value} key={`dateFormatOption-${dateFormat.value}`}>{dateFormat.text}</MenuItem>);
  const timeFormatOptions = timeFormatTypes && Object.values(timeFormatTypes).map((timeFormat: any) => <MenuItem value={timeFormat.value} key={`timeFormatOption-${timeFormat.value}`}>{timeFormat.text}</MenuItem>);

  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState<string>("");
  const [dashboardAlerts, setDashboardAlerts] = useState<boolean>(false);
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState<string>("");
  const [phoneErrorMsg, setPhoneMessageError] = useState<string>("");
  const [usernameErrorMsg, setUsernameMessageError] = useState<string>("");
  const [isDialogOpen, openDialog] = useState<boolean>(false);
  const [isOpenCustomersNicknames, setOpenCustomersNicknames] = useState<boolean>(false);
  const [isDataAndPrivacyDrawerOpen, setIsDataAndPrivacyDrawerOpen] = useState<boolean>(false);
  const [passwordVisibility, setPasswordVisibility] = useState<IObject>({
    currentPasswordVisibility: false,
    newPasswordVisibility: false,
    confirmPasswordVisibility: false
  });
  const [emailErrorMsg, setEmailMessageError] = useState<string>("");
  const [userInfo, setUserInfo] = useState<IUserInfo>({});
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const startLoader = useStoreActions((actions) => actions.loader.startLoader);
  const finishLoader = useStoreActions((actions) => actions.loader.finishLoader);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const changePassword = useStoreActions((action) => action.users.updatePassword);

  const user = useStoreState((state) => state.users.me);
  const userPref = useStoreState((state) => state.users.userPreferences);
  const updateMe = useStoreActions((action) => action.users.updateMe);
  const updateUserPreferences = useStoreActions((actions) => actions.users.updateUserPreferences);
  const allCustomers = useStoreState((state) => Object.values(state.customers.allCustomers)
    .map((customer: any) => ({ ...customer, nickname: userPref?.nicknames?.[customer.id] || "" }))
    .sort((a: any, b: any) => {
      const aName = a.name?.toUpperCase() || ""
      const bName = b.name?.toUpperCase() || ""
      const aNickname = a.nickname?.toUpperCase() || ""
      const bNickname = b.nickname?.toUpperCase() || ""

      if (aNickname && bNickname)
        return aNickname.localeCompare(bNickname)
      else if (aNickname)
        return -1
      else if (bNickname)
        return 1
      else
        return aName.localeCompare(bName)
    }))

  const [is2FA, setIs2FA] = useState<boolean>(user?.is2FA || false);
  const [lockDnD, setLockDnD] = useState<boolean>(userPref?.lockItemsReorder || false);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const passUserInfo = useCallback(() => {
    const currentUser = {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phone: user?.phone || "",
      username: user?.username || "",
      language: userPref?.professionalLanguage || "en",
      lockItemsReorder: userPref?.lockItemsReorder || false,
      temperatureScale: user.temperatureScale,
      timeFormat: user.timeFormat,
      dateFormat: user.dateFormat,
      is2FA: user?.is2FA,
    };

    let { phone = "" } = currentUser;
    const firstChar = phone.charAt(0);
    const first2Char = firstChar + phone.charAt(1);

    if (firstChar !== "+") {
      phone = "+" + phone;
    }

    if (first2Char === "00") {
      phone.substring(2);
      phone = "+" + phone;
    }

    currentUser.phone = phone;

    setUserInfo(currentUser);

  }, [user, userPref]);

  useEffect(() => {
    passUserInfo();
  }, [passUserInfo, types, user]);

  useEffect(() => {
    if (_.isEmpty(userPref)) {
      return;
    }
    setDashboardAlerts(userPref.showClearFilterAlerts);
    setLockDnD(userPref.lockItemsReorder);
  }, [userPref]);

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(t`required field`),
    password: Yup.string()
      .required(t`required field`)
      .min(8, t`Password is too short - should be 8 chars minimum.`)
      .matches(/[A-Z]/, t`password must include capital letters`)
      .matches(/[a-z]/, t`password must include lower case letters`)
      .matches(/[0-9]/, t`password must include numbers`),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t`Passwords must match`)
      .required(t`Password confirm is required`)
  });

  const validateEmail = (email: any) => {
    return isEmail(email);
  };

  const formValidtion = (phone: string, email: string) => {
    if (phone.length < 11 && phone.length !== 0) {
      return {
        pass: false,
        errorType: "phone",
        errorMsg: "please add valid phone number"
      };
    }

    if (email.length >= 1 && !validateEmail(email)) {
      return {
        pass: false,
        errorType: "email",
        errorMsg: "please add valid email"
      };
    }

    return { pass: true, errorType: "", errorMsg: "" };
  };

  const handleInputsChange = (event: any) => {
    const {
      target: { value, name }
    } = event;

    setUserInfo({ ...userInfo, [name]: value });
    submitDisabled && setSubmitDisabled(false);
  };
  const handlePhoneChange = (value: any) => {
    setUserInfo({ ...userInfo, phone: value });
    submitDisabled && setSubmitDisabled(false);
  };

  const setValidationErrors = () => {
    const { phone = "", email = "" } = userInfo;
    const newPhone = phone.replace(/[+]/g, "");
    const validationPassed = formValidtion(newPhone, email);
    const { pass, errorType, errorMsg } = validationPassed;

    setPhoneMessageError("");
    setEmailMessageError("");

    if (pass === true) {
      return true;
    }

    errorType === "phone"
      ? setPhoneMessageError(errorMsg)
      : setEmailMessageError(errorMsg);

    return false;
  };

  const pickShortProps = (data: any) => {
    return _.pick(data, [
      "firstName",
      "lastName",
      "username",
      "email",
      "password",
      "permissions",
      "username",
      "language",
      "timeFormat",
      "dateFormat"
    ]);
  };

  const save = () => {
    if (!setValidationErrors()) {
      return;
    }

    const { phone = "", firstName = "", lastName = "", username } = userInfo;

    setFirstNameErrorMsg("");
    setLastNameErrorMsg("");
    setUsernameMessageError("");

    if (!username) {
      setUsernameMessageError("required field");
      return;
    } else if (username.length < 6) {
      setUsernameMessageError(
        "your username should include at least 6 characters"
      );
      return;
    } else if (username.length > 50) {
      setUsernameMessageError(
        "your username can include maximum 50 characters"
      );
      return;
    }

    if (!firstName) {
      setFirstNameErrorMsg("required field");
      return;
    }

    if (!lastName) {
      setLastNameErrorMsg("required field");
      return;
    }

    const newPhone = phone.replace(/[+]/g, "");

    const updatedUser = {
      ...pickShortProps(userInfo),
      temperatureScale: +temperatureScale,
      phone: newPhone,
      is2FA
    };
    startLoader();
    updateMe(updatedUser)
      .then(() => {
        setSubmitDisabled(true);
        if (dashboardAlerts !== userPref.showClearFilterAlerts || userPref.professionalLanguage !== language || userPref.lockItemsReorder !== lockDnD) {
          return updateUserPreferences({ showClearFilterAlerts: dashboardAlerts, professionalLanguage: language, lockItemsReorder: lockDnD });
        }
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const handleDialog = () => {
    openDialog(!isDialogOpen);
    setPasswordVisibility({
      currentPasswordVisibility: false,
      newPasswordVisibility: false,
      confirmPasswordVisibility: false
    });
  };

  const handlePasswordSubmit = (values: any) => {
    const { password, oldPassword } = values;
    startLoader();

    changePassword({ oldPassword, newPassword: password })
      .then(() => {
        handleDialog();
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => finishLoader());
  };

  const goBack = () => {
    history.push("/dashboard");
  };

  const updateCustomersNicknames = (values: any) => {
    updateUserPreferences({ nicknames: values })
      .finally(() => {
        setOpenCustomersNicknames(false)
      })
  }

  const {
    firstName,
    lastName,
    email,
    phone = "",
    username,
    dateFormat,
    language,
    temperatureScale = 1,
    timeFormat
  } = userInfo;

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.grid}>
        <TopBar
          title={t`My Profile`}
          leftIconComponent={<ArrowBack />}
          leftAction={(!isDialogOpen && !isOpenCustomersNicknames && !isDataAndPrivacyDrawerOpen) ? goBack : () => { openDialog(false); setOpenCustomersNicknames(false); setIsDataAndPrivacyDrawerOpen(false) }}
        />
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            overflow: "hidden",
            overflowY: "auto"
          }}
        >
          <div>
            <Avatar className={classes.avatar} />
          </div>
          {!isDialogOpen && !isOpenCustomersNicknames && !isDataAndPrivacyDrawerOpen ? (
            <>
              <List className={classes.list} disablePadding={true} dense={true}>
                <TextField
                  variant={"outlined"}
                  name={"username"}
                  label={"Username"}
                  placeholder={"Username"}
                  value={username || ""}
                  className={classes.textFieldInputClass}
                  onChange={handleInputsChange}
                />
                {usernameErrorMsg && (
                  <FormHelperText
                    error={true}
                    className={classes.usernameErrorStyle}
                  >
                    {usernameErrorMsg}
                  </FormHelperText>
                )}
                <TextField
                  variant={"outlined"}
                  name={"firstName"}
                  label={"First Name"}
                  placeholder={"First Name"}
                  value={firstName || ""}
                  className={classes.textFieldInputClass}
                  onChange={handleInputsChange}
                />
                {firstNameErrorMsg && (
                  <FormHelperText
                    error={true}
                    className={classes.usernameErrorStyle}
                  >
                    {firstNameErrorMsg}
                  </FormHelperText>
                )}
                <TextField
                  variant={"outlined"}
                  name={"lastName"}
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  value={lastName || ""}
                  className={classes.textFieldInputClass}
                  onChange={handleInputsChange}
                />
                {lastNameErrorMsg && (
                  <FormHelperText
                    error={true}
                    className={classes.usernameErrorStyle}
                  >
                    {lastNameErrorMsg}
                  </FormHelperText>
                )}
                <MuiPhoneNumber
                  defaultCountry={"us"}
                  placeholder="Phone Number"
                  label="Phone Number"
                  value={phone || ""}
                  onChange={handlePhoneChange}
                  disableAreaCodes={true}
                  variant="outlined"
                  className={classes.textFieldInputClass}
                />
                {phoneErrorMsg && (
                  <FormHelperText
                    error={true}
                    className={classes.errorStyle}
                  >
                    {phoneErrorMsg}
                  </FormHelperText>
                )}
                {
                  displayFlags.enableLanguageSelection && (
                    <div className={classes.selectContainer}>
                      <FormControl fullWidth>
                        <InputLabel className={classes.inputLabel}>{t`Language`}</InputLabel>
                        <Select
                          variant="outlined"
                          name="language"
                          label={t`Language`}
                          className={classes.input}
                          value={`${language}`}
                          onChange={handleInputsChange}
                          IconComponent={SvgArrow}
                          MenuProps={{ classes: { paper: classes.menuItemsContainer } }}
                        >
                          {languagesOptions}
                        </Select>
                      </FormControl>
                    </div>
                  )
                }
                <TextField
                  variant={"outlined"}
                  name={"email"}
                  label={"Email Address"}
                  placeholder={"Email Address"}
                  value={email || ""}
                  type="email"
                  className={classes.textFieldInputClass}
                  onChange={handleInputsChange}
                />
                {emailErrorMsg && (
                  <FormHelperText
                    error={true}
                    className={classes.usernameErrorStyle}
                  >
                    {emailErrorMsg}
                  </FormHelperText>
                )}
                <div className={clsx(classes.selectContainer, classes.capitalize)}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel}>{t`Temperature Scale`}</InputLabel>
                    <Select
                      variant="outlined"
                      name="temperatureScale"
                      label={t`Temperature Scale`}
                      className={classes.input}
                      value={`${temperatureScale}`}
                      onChange={handleInputsChange}
                      IconComponent={SvgArrow}
                      MenuProps={{ classes: { paper: classes.menuItemsContainer } }}
                    >
                      {temperatureScaleOptions}
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.selectContainer}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel}>{t`Time Format`}</InputLabel>
                    <Select
                      variant="outlined"
                      name="timeFormat"
                      label={t`Time Format`}
                      className={classes.input}
                      value={`${timeFormat}`}
                      onChange={handleInputsChange}
                      IconComponent={SvgArrow}
                      MenuProps={{ classes: { paper: classes.menuItemsContainer } }}
                    >
                      {timeFormatOptions}
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.selectContainer}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel}>{t`Date Format`}</InputLabel>
                    <Select
                      variant="outlined"
                      name="dateFormat"
                      label={t`Date Format`}
                      placeholder={t`Date Format`}
                      className={classes.input}
                      value={`${dateFormat}`}
                      onChange={handleInputsChange}
                      IconComponent={SvgArrow}
                      MenuProps={{ classes: { paper: classes.menuItemsContainer } }}
                    >
                      {dateFormatOptions}
                    </Select>
                  </FormControl>
                </div>

                <div className={clsx(classes.selectContainer, classes.switchContainer)}>
                  <Typography className={classes.switchTitle}>{t`Hide filter alerts`}</Typography>
                  <CoolSwitch
                    color={"default"}
                    checked={!dashboardAlerts}
                    switchChange={() => {
                      setDashboardAlerts(!dashboardAlerts);
                      submitDisabled && setSubmitDisabled(false);
                    }}
                  />
                </div>

                {!disable2FA && <div className={clsx(classes.selectContainer, classes.switchContainer)}>
                  <Typography className={classes.switchTitle}>{t`Enable email 2-factor-authentication`}</Typography>
                  <CoolSwitch
                    color={"default"}
                    checked={is2FA}
                    switchChange={() => {
                      setIs2FA(!is2FA);
                      submitDisabled && setSubmitDisabled(false);
                    }}
                  />
                </div>}

                <div className={clsx(classes.selectContainer, classes.switchContainer)}>
                  <Typography className={classes.switchTitle}>{t`Enable unit/group reorder`}</Typography>
                  <CoolSwitch
                    color={"default"}
                    checked={!lockDnD}
                    switchChange={() => {
                      setLockDnD(!lockDnD);
                      submitDisabled && setSubmitDisabled(false);
                    }}
                  />
                </div>

                <div className={classes.buttonBlock} onClick={handleDialog}>
                  <Typography>{t`Change Password`}</Typography>
                  <ArrowForwardIos className={classes.forwardArrow} />
                </div>

                <div className={classes.buttonBlock} onClick={() => setOpenCustomersNicknames(true)}>
                  <Typography>{t`Customers Nicknames`}</Typography>
                  <ArrowForwardIos className={classes.forwardArrow} />
                </div>

                <div className={classes.buttonBlock} onClick={() =>  history.push('/data-privacy')}>
                  <Typography>{t`Data & Privacy`}</Typography>
                  <ArrowForwardIos className={classes.forwardArrow} />
                </div>
              </List>

              <div className={classes.saveButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.saveButtonStyle}
                  onClick={save}
                  disabled={submitDisabled}
                  red
                >
                  {t`Save`}
                </Button>
              </div>
            </>
          ) : isDialogOpen ? (
            <>
              <Typography className={classes.resetPasswordDialogMessage}>
                {t` Your password should include at least 8 characters, 1 number, 1
                  capital letter, 1 special characters`}
              </Typography>
              <Formik
                initialValues={{ oldPassword: "", password: "", confirmPassword: "" }}
                onSubmit={(values) => handlePasswordSubmit(values)}
                enableReinitialize={true}
                validationSchema={validationSchema}
                render={({ values, errors, setFieldValue, dirty, ...formikProps }) => {
                  return (
                    // @ts-ignore
                    <Form translate="yes" className={classes.form}>
                      <div className={classes.fieldsContainer}>
                        <InputLabel
                          className={classes.labelStyle}
                        >{t`Current Password`}</InputLabel>
                        <TextField
                          name="oldPassword"
                          variant={"outlined"}
                          placeholder={t`Current Password`}
                          value={values?.oldPassword}
                          error={!!errors.oldPassword}
                          helperText={errors.oldPassword}
                          onChange={(event: any) => setFieldValue("oldPassword", event.target.value)}
                          className={classes.inputFieldStyle}
                          type={
                            passwordVisibility.currentPasswordVisibility
                              ? "text"
                              : "password"
                          }
                        />
                        <InputLabel
                          className={classes.labelStyle}
                        >{t`New Password`}</InputLabel>
                        <TextField
                          name="password"
                          variant={"outlined"}
                          placeholder={t`Password (8+ characters)`}
                          value={values?.password}
                          error={!!errors.password}
                          helperText={errors.password}
                          onChange={(event: any) => setFieldValue("password", event.target.value)}
                          className={classes.inputFieldStyle}
                          type={
                            passwordVisibility.newPasswordVisibility
                              ? "text"
                              : "password"
                          }
                        />
                        <InputLabel
                          className={classes.labelStyle}
                        >{t`Repeat Password`}</InputLabel>
                        <TextField
                          name="confirmPassword"
                          variant={"outlined"}
                          placeholder={t`Confirm Password`}
                          value={values?.confirmPassword}
                          error={!!errors.confirmPassword}
                          helperText={errors.confirmPassword}
                          onChange={(event: any) => setFieldValue("confirmPassword", event.target.value)}
                          className={classes.inputFieldStyle}
                          type={
                            passwordVisibility.confirmPasswordVisibility
                              ? "text"
                              : "password"
                          }
                        />
                      </div>
                      <div className={classes.saveButtonContainer}>
                        <Button
                          type="submit"
                          variant="contained"
                          className={classes.saveButtonStyle}
                          disabled={!dirty}
                          red
                        >
                          {t`Save`}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              />
            </>)
            : isOpenCustomersNicknames ? (
              <>
                <div >
                  <Typography >{t`Update Customers Nicknames`}</Typography>
                </div>
                <Formik
                  initialValues={allCustomers.reduce((obj: any, customer: any) => {
                    obj[customer.id] = customer.nickname || "";
                    return obj;
                  }, {})}
                  onSubmit={updateCustomersNicknames}
                  enableReinitialize={true}
                >
                  {({ handleSubmit, values, setFieldValue, dirty, errors, touched, ...restFormikProps }) => (
                    <form onSubmit={handleSubmit} >
                      <div >
                        {
                          allCustomers.map(({ name, id }) => {
                            return (
                              <div key={id}>
                                <InputLabel className={classes.labelStyle}> {name} </InputLabel>
                                <TextField
                                  name={id}
                                  variant={"outlined"}
                                  // placeholder={`${nickname}`}
                                  value={values?.[id]}
                                  onChange={(event: any) => setFieldValue(id, event.target.value)}
                                  className={classes.inputFieldStyle}
                                />
                              </div>
                            )
                          })
                        }
                      </div>

                      <div >
                        <Button onClick={() => setOpenCustomersNicknames(false)} width={150} white marginRight >
                          {t`Cancel`}
                        </Button>
                        <Button type="submit" width={150} disabled={!dirty}>
                          {t`Save`}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </>) : <></>
          }
        </div>
      </Grid>
    </div>
  );
};

export default ProfileScreen;
