import React, { useState, useEffect, useMemo } from 'react';
import { t } from 'ttag';
import Header from '@components/Header/Header';
import ServiceNavigationBar from '@components/Menu/ServiceNavigationBar';
import { useStoreState } from '@models/RootStore';
import useStyles from './styles';
import UseElasticDashboard from '@hooks/UseElasticDashboard';
import GraphPlaceHolder from '../Reports/GraphPlaceHolder';
import FilterRequire from '@components/FilterRequire/FilterRequire';

/**
 * AdvancedReports component handles generating embed URLs and loading them into an iframe.
 * It allows selecting a report type from a dropdown before generating the report.
 */
const AdvancedReports = (props) => {
  const classes = useStyles();
  const { generateEmbedURLs } = UseElasticDashboard();

  const siteId = useStoreState((s) => s.selections.selections.siteId);
  const customerId = useStoreState((s) => s.selections.selections.customerId);

  const [selectedReportType, setSelectedReportType] = useState('');
  const [dashboards, setDashboards] = useState([]);

  // Fetch dashboards when customerId or siteId changes
  useEffect(() => {
    if (!customerId) {
      return;
    }
    const fetchDashboards = async () => {
      const data = await generateEmbedURLs(customerId, siteId);
      setDashboards(data);
      if (data.length > 0) {
        setSelectedReportType(data[0].name);
      }
    };

    fetchDashboards();
  }, [customerId, siteId]);

  const selectedDashboard = useMemo(
    () =>
      dashboards.find((frame) => frame.name === selectedReportType)?.url || '',
    [dashboards, selectedReportType]
  );

  const handleReportTypeChange = (event) => {
    setSelectedReportType(event.target.value);
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header hideUnitSelection hideSystemSelection />
        {!customerId ? (
          <FilterRequire type={t`customer`} expand={true} />
        ) : (
          <div className={classes.mainContainer}>
            <select
              value={selectedReportType}
              onChange={handleReportTypeChange}
              className={classes.select}
            >
              {dashboards.map((frame, index) => (
                <option
                  key={index}
                  value={frame.name}
                  className={classes.option}
                >
                  {frame.name}
                </option>
              ))}
            </select>
            {selectedReportType ? (
              <div className={classes.iframeContainer}>
                <iframe
                  src={selectedDashboard}
                  className={classes.iframe}
                  title="Dashboard Frame"
                />
              </div>
            ) : (
              <GraphPlaceHolder type={t`report type`} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvancedReports;
