import { Button, Typography } from "@material-ui/core";
import { Timeline, AssistantOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { t } from "ttag";
import {
  AutomationLogic,
  Control as SvgControl,
  Dashboard,
  Diagnostics as SvgDiagnostics,
  Bacnets as SvgBacnet,
  Notifications as SvgAlerts,
  OfflineBoltOutlined,
  Settings as SvgSettings,
  Site as SvgSite,
  SupportTicket,
  SvgExpandLess,
  SvgExpandMore,
  User as SvgUser,
} from "../../icons/";
import ReportProblem from "../../screens/ReportProblem/ReportProblem";
import assetsService from "../../services/assetsService";

import packageJSON from "../../../package.json";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import NavigationMenuItem from "./NavigationMenuItem";
import useStyles from "./ServiceNavigationBar.style";

const AutomationIcon = (props: any) => <AutomationLogic opacity={1} {...props} style={{ transform: "scale(0.9)" }} />;
const ControlIcon = (props: any) => <SvgControl opacity={1} {...props} />;
const PowerIcon = (props: any) => <OfflineBoltOutlined color="#fff" {...props} />;

const ServiceNavigationBar: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const selectedUnitId = useStoreState((s) => s.selections.selections.unitId);
  const selectedSystemId = useStoreState((s) => s.selections.selections.systemId);
  const selections = useStoreState((state) => state.selections.selections);
  const setSelectedAlertObj = useStoreActions((actions) => actions.setSelectedAlert);
  const selectedSystems = useStoreState((s) => s.selections.getSystemsBySelection);
  const configFile = useStoreState((s) => s.config);
  const me = useStoreState((s) => s.users.me);

  const { enableBacnet, enableAssistant } = me;

  const { customerId } = selections;
  const displayFlagsMap = useStoreState((state) => state.users.displayFlagsMap);
  const displayFlags = displayFlagsMap[customerId || "all"] || {};

  let defaultSystemId = useStoreState(
    (s) => s.systems.allSystems[Object.keys(s.systems.allSystems)[0]]?.id
  );

  const dropDownNavigation = useStoreState((s) => s.isDropDownOpen);
  const globalErrorReport = useStoreState((state) => state.errorMessage.globalErrorReport);
  const setDropDownNavigation = useStoreActions((a) => a.setDropDownNavigation);

  const { showGlobalReport } = globalErrorReport

  selections.siteId && selectedSystems.length && (defaultSystemId = selectedSystems[0].id);

  const [openReport, setOpenReport] = useState<boolean>(false);

  const showReportWindow = showGlobalReport || openReport

  const goToDashboardLogo = () => {
    history.push("/dashboard");
  };

  const itemsContainer = document.getElementById("itemsContainer");

  useEffect(() => {
    const navBarScrollAnchor = Number(localStorage.getItem("navBarScrollAnchor") || "0");
    if (navBarScrollAnchor && itemsContainer) {
      itemsContainer.scrollTop = navBarScrollAnchor;
    }
  }, [itemsContainer]);


  const renderSiteManagementSection = () => {
    if (!displayFlags.showSiteManagement) {
      return null;
    }

    if (enableBacnet) {
      const menuItems = [
        {
          name: t`Site Management`,
          path: '/site-management',
          show: true,
        },
        {
          name: t`BACnet Mapping`,
          path: '/automation/bacnet-detection/',
          show: true,
        },
      ];

      return (
        <NavigationMenuItem
          Icon={SvgSite}
          name={t`Site Management`}
          endIcon={[SvgExpandMore, SvgExpandLess]}
          disabled={!displayFlags.enableSiteManagement}
          showDropDown={dropDownNavigation.siteManagement}
          setShowDropDown={() => setDropDownNavigation('siteManagement')}
          dropdown={menuItems}
        />
      );
    }

    return (
      <NavigationMenuItem
        Icon={SvgSite}
        name={t`Site Management`}
        path={'/site-management'}
        disabled={!displayFlags.enableSiteManagement}
      />
    );
  };

  return (
    <div className={classes.navigationBarContainer}>
      <Button
        aria-label="app logo"
        className={classes.navLogo}
        onMouseUp={goToDashboardLogo}
        classes={{ label: classes.label }}
      // style={hideLogo ? { display: "none" } : {}}
      >
        <img
          src={assetsService.getResourcePath("logo.png")}
          className={classes.navIcon}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.style.visibility = "hidden";
          }}
          alt=""
        />
      </Button>
      <div className={classes.itemsContainer}
        id="itemsContainer"
        onScroll={() => localStorage.setItem("navBarScrollAnchor", String(itemsContainer?.scrollTop || "0"))}
      >

        <NavigationMenuItem
          Icon={Dashboard}
          name={t`Dashboard`}
          path={"/dashboard"}
          onNavigate={() => {
            updateSelections({ type: "system", data: null });
          }}
          disabled={!displayFlags.enableDashboard}
        />
        {displayFlags.showControl && <NavigationMenuItem
          Icon={ControlIcon}
          name={t`Remote Control`}
          path={"/control"}
          disabled={!displayFlags.enableControl}
        />}
        {/* {displayFlags.showReports && <NavigationMenuItem
          Icon={Timeline}
          name={t`Reports`}
          path={"/site-reports"}
          disabled={!displayFlags.enableReports}
        />} */}
        {me?.enableReportsScheduler ?
          <NavigationMenuItem
            Icon={Timeline}
            name={t`Reports`}
            endIcon={[SvgExpandMore, SvgExpandLess]}
            dropdown={[
              {
                name: t`Online Reports`,
                path: "/site-reports-live",
                isDisabled: !displayFlags.enableReports,
                show: displayFlags.showReports
              },
              {
                name: t`Report Scheduler`,
                path: "/site-reports-schedulers",
                isDisabled: false,//!displayFlags?.enableReportsScheduler,
                show: true//displayFlags?.showReportsScheduler
              },
              {
                name: t`Advanced Reports`,
                path: "/advanced-reports",
                isDisabled: !displayFlags.enableReports,
                show: displayFlags.showReports
              },
            ]}
            showDropDown={dropDownNavigation.reports}
            setShowDropDown={() => setDropDownNavigation("reports")}
          /> :
          <NavigationMenuItem
            Icon={Timeline}
            name={t`Reports`}
            path={"/site-reports-live"}
            disabled={!displayFlags.enableReports}
          />}
        {<NavigationMenuItem
          Icon={SvgDiagnostics}
          name={t`Diagnostics Tools`}
          endIcon={[SvgExpandMore, SvgExpandLess]}
          dropdown={[
            {
              name: t`Unit Diagnostics`,
              path: `/unit-stats/${selectedUnitId ? selectedUnitId : ""}`,
              show: displayFlags.showUnitDiagnostics,
              isDisabled: !displayFlags.enableUnitDiagnostics
            },
            {
              name: t`System Diagnostics`,
              path: `/system-diagnostics/${selectedSystemId ? selectedSystemId : ""}`,
              show: displayFlags.showSystemDiagnostics,
              isDisabled: !displayFlags.enableSystemDiagnostics
            },
            {
              name: t`Unit Statistics`,
              path: `/unit-statistics/${selectedUnitId ? selectedUnitId : ""}`,
              show: displayFlags.showUnitStatistics,
              isDisabled: !displayFlags.enableUnitStatistics
            },
            {
              name: t`Unit Usage`,
              path: `/unit-usage/${selectedUnitId ? selectedUnitId : ""}`,
              show: displayFlags.showUnitUsagePattern,
              isDisabled: !displayFlags.enableUnitUsagePattern
            },
            {
              name: t`Activity Audit`,
              path: "/audits",
              show: displayFlags.showAuditLog,
              isDisabled: !displayFlags.enableAuditLog
            },
            {
              name: t`Advanced unit search`,
              path: "/advanced-unit-search",
              isDisabled: !(displayFlags.enableAdvancedSearch !== false),
              show: false
              // show: displayFlags.showAdvancedSearch !== false
            },
            {
              name: t`Advanced searches & logs`,
              path: "/advanced-unit-search-logs",
              isDisabled: !(displayFlags.enableAdvancedSearchLogs !== false),
              show: false
              // show: displayFlags.showAdvancedSearchLogs !== false
            }
          ]}
          showDropDown={dropDownNavigation.diagnostics}
          setShowDropDown={() => setDropDownNavigation("diagnostics")}
        />}
        {displayFlags.showAlertLog && <NavigationMenuItem Icon={SvgAlerts} name={t`Alerts Log`} path={"/alerts"} onNavigate={() => {
          setSelectedAlertObj(null);
        }}
          disabled={!displayFlags.enableAlertLog}
        />}
        {renderSiteManagementSection()}
        {<NavigationMenuItem
          Icon={SvgSettings}
          name={t`Control Settings`}
          endIcon={[SvgExpandMore, SvgExpandLess]}
          dropdown={[
            {
              name: t`App Control`,
              path: "/settings/unit",
              isDisabled: !displayFlags.enableSettingsUnitAppControls,
              show: displayFlags.showSettingsUnitAppControls
            },
            {
              name: t`WRC Restrictions`,
              path: "/settings/restricts",
              isDisabled: !displayFlags.enableSettingsWRCRestrictions,
              show: displayFlags.showSettingsWRCRestrictions
            },
            {
              name: t`Groups`,
              path: "/settings/groups",
              isDisabled: !displayFlags.enableSettingsGroups,
              show: displayFlags.showSettingsGroups
            },
            {
              name: t`Setback`,
              path: "/settings/setback",
              isDisabled: !displayFlags.enableAwayMode,
              show: displayFlags.showAwayMode
            }
          ]}
          showDropDown={dropDownNavigation.settings}
          setShowDropDown={() => setDropDownNavigation("settings")}
        />}
        {displayFlags.showUserManagementUsers && <NavigationMenuItem Icon={SvgUser} name={t`User Management`} path={"/user"} disabled={!displayFlags.enableUserManagementUsers} />}
        {<NavigationMenuItem
          Icon={AutomationIcon}
          name={t`Automation`}
          path={`/PowerDistributionFalse`}
          endIcon={[SvgExpandMore, SvgExpandLess]}
          dropdown={[
            // {
            //   name: t`Scheduling`,
            //   path: "/automatic/scheduling",
            //   isDisabled: !displayFlags.enableAutomationLogic,
            //   show: displayFlags.showAutomationLogic
            // },

            {
              name: t`Auto Changeover`,
              path: "/automatic/changeover",
              isDisabled: !displayFlags.enableAutomaticChangeover,
              show: displayFlags.showAutomaticChangeover
            },
            {
              name: t`Interlocking`,
              path: "/automation-logic",
              isDisabled: !displayFlags.enableAutomationLogic,
              show: displayFlags.showAutomationLogic
            },
            {
              name: t`Procedures`,
              path: "/commissioning-prodecuders",
              show: displayFlags.showScriptsLogs,
              isDisabled: !displayFlags.enableScriptsLogs
            },
            {
              name: t`Test Procedures & Logs`,
              path: "/commissioning-logs",
              show: displayFlags.showScriptsProcedures,
              isDisabled: !displayFlags.enableScriptsProcedures
            },
            {
              name: t`Notification Settings`,
              path: "/settings/alerts",
              isDisabled: !displayFlags.enableSettingsNotifications,
              show: displayFlags.showSettingsNotifications
            },
            {
              name: t`Anomaly Rules`,
              path: "/anomalies-settings",
              isDisabled: !displayFlags.enableAnomalies && !displayFlags.enableOperationalAnomalies,
              show: displayFlags.showAnomalies
            },
            {
              name: t`Smart Rules`,
              path: "/smart-rules",
              isDisabled: !displayFlags.enableSmartAlerts,
              show: displayFlags.showSmartAlerts
            }
          ]}
          showDropDown={dropDownNavigation.operationAutomate}
          setShowDropDown={() => setDropDownNavigation("operationAutomate")}
        />
        }
        {
          <NavigationMenuItem
            Icon={PowerIcon}
            name={t`Power Distribution`}
            path={`/PowerDistributionFalse`}
            endIcon={[SvgExpandMore, SvgExpandLess]}
            dropdown={[
              {
                name: t`Usage Analysis`,
                path: "/tenantReports",
                isDisabled: !displayFlags.enablePowerDistributionUsageAnalysis,
                show: displayFlags.showPowerDistributionUsageAnalysis
              },
              {
                name: t`Tenants Settings`,
                path: "/PowerDistribution",
                isDisabled: !displayFlags.enablePowerDistributionZonesSetting,
                show: displayFlags.showPowerDistributionZonesSetting
              },
              {
                name: t`Report Scheduler`,
                path: "/scheduler",
                isDisabled: !displayFlags.enablePowerDistributionReportScheduler,
                show: displayFlags.showPowerDistributionReportScheduler
              },
              {
                name: t`Power Meter Settings`,
                path: "/power-meters-settings",
                isDisabled: !displayFlags.enablePowerDistributionZonesSetting,
                show: displayFlags.showPowerDistributionZonesSetting
              }
            ]}
            showDropDown={dropDownNavigation.powerDistribution}
            setShowDropDown={() => setDropDownNavigation("powerDistribution")}
          />}
        {enableAssistant && <NavigationMenuItem Icon={AssistantOutlined} name={t`Assistant`} path={"/assistant"} disabled={!enableAssistant}/>}
        {configFile?.enableOpenTicket && <NavigationMenuItem
          Icon={SupportTicket}
          name={t`Open Support Ticket`}
          path={""}
          onClick={() => {
            if (configFile?.supportBtnLink?.length) {
              window?.open(configFile?.supportBtnLink, '_blank')?.focus();
              return;
            }
            setOpenReport(true);
          }}
        />}

        {showReportWindow && (
          <ReportProblem openReport={showReportWindow} onClose={() => setOpenReport(false)} />
        )}
      </div>
      <div className={classes.imaginaryHeight}></div>
      {me.impersonatingUserId && <div className={classes.impressionModeContainer}>
        <Typography className={classes.impressionText}>{t`Impersonation Mode`}</Typography></div>}
      <Typography variant="caption" className={classes.versionText}>
        {t`Version`} {packageJSON.version}
      </Typography>
    </div>
  );
};

export default ServiceNavigationBar;
