import React, { useEffect } from 'react';
import { t } from 'ttag';
import { Paper } from '@material-ui/core';
import useStyles from './styles';
import Header from '@components/Header/Header';
import ServiceNavigationBar from '@components/Menu/ServiceNavigationBar';
import { useStoreState } from '@models/RootStore';


const Assistant = (props) => {
  const classes = useStyles();
  const me = useStoreState((s) => s.users.me);
  const { id: userId } = me;

  useEffect(() => {
    // Dynamically load the Dialogflow Messenger script
    const script = document.createElement('script');
    script.addEventListener("df-request-sent", function (event) {
      event.detail.request.queryParams = {
          payload: { userId }
      };
    });
    script.src =
      'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSiteSelection
          hideSystemSelection
          hideUnitSelection
          screenTitle="siteBacNetUnits"
        />
        <Paper elevation={0} className={classes.paperContainer}>
        <df-messenger
            intent="WELCOME"
            chat-title="CA_assistant"
            agent-id="d8d85548-a5a1-40a5-89f4-245b713442a5"
            language-code="en"
            session-id={userId}
            user-id={userId}

          />
          
        </Paper>
      </div>
    </div>
  );
};

export default Assistant;
