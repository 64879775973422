import { Monitoring as monitoringSdk} from 'coolremote-sdk';
import { useStoreActions } from '@models/RootStore';


const UseElasticDashboard = () => {
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  // generate embed URL based on provided filters
  const generateEmbedURLs = async (customerId, siteId) => {
    try {
      const result = await monitoringSdk.generateEmbedURLs(customerId, siteId);
      return result;
    } catch (error) {
      addMessage({ message: error.message || 'An error occurred' });
    }
  };

  return {
    generateEmbedURLs
  };
};

export default UseElasticDashboard;
