import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: any) =>
({
  drawerStyle: {
    left: "auto !important",
    right: "auto !important"
  },
  root: {
    width: "100%",
    left: "auto",
    right: "auto",
    margin: "auto",

    drawerPaper: {
      width: "85%",
      background: theme.gradiants.control || "linear-gradient(to right, #491d47 2%, #381739 65%)",
      maxWidth: "500px",
      position: "absolute",
      flexDirection: "row",
      margin: "auto",
      top: "50%",
      bottom: "50%"
    },
    bigAvatar: {
      transform: "scale(0.8727)",
      marginTop: "14px",
      marginBottom: "5px"
    },
    menuHeader: {
      flexFlow: "column",
      alignItems: "center",
      paddingTop: "14px",
      marginBottom: "33px !important"
    },
    divider: {
      opacity: 0.2,
      background: "#fdfdfe"
    },
    listItemText: {
      opacity: 0.7,
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      fontSize: "16px",
      color: theme.palette.colors.gray || "#545964"
    },
    closeButton: {
      alignSelf: "flex-start",
      marginLeft: "-8px",
      marginTop: "-12px",
      padding: "12px"
    },
    menuBody: {
      paddingLeft: "27px",
      height: "94%",
      maxHeight: "850px",
      margin: 0

    },
    usernameStyle: {
      fontFamily: "Roboto",
      fontSize: "20px",
      textAlign: "center",
      color: "#fff",
      lineHeight: "normal",
      maxWidth: "calc(100% - 20px)",
      wordBreak: "break-word"
    },
    roleStyle: {
      fontFamily: "Roboto",
      fontSize: "14px",
      color: alpha("#fff", 0.7),
      lineHeight: "normal"
    },
    listItemStyle: {
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      paddingLeft: "3px",
      paddingRight: "14px",
      height: "54px"
    },
    iconTextContainer: {
      display: "inline-flex",
      justifyItems: "center",
      justifyContent: "center"
    },
    menuIconsStyle: {
      minWidth: "17px",
      minHeight: "17px",
      alignSelf: "center",
      paddingRight: "11px",
      fontSize: "20px",
      fill: alpha("#fdfdfe", 0.8)
    },
    "& div:first-of-type:first-child": {
      width: "100%",
      left: "auto",
      right: "auto",
      alignItems: "center"
    }
  },
  drawerPaper: {
    width: "85%",
    background: theme.gradiants.control || "linear-gradient(to right, #491d47 2%, #381739 65%)",
    maxWidth: "500px",
    position: "absolute",
    flexDirection: "row",
    margin: "auto",
    top: "50%",
    bottom: "50%"
  },
  bigAvatar: {
    transform: "scale(0.8727)",
    marginTop: "14px",
    marginBottom: "5px"
  },
  menuHeader: {
    flexFlow: "column",
    alignItems: "center",
    paddingTop: "14px",
    marginBottom: "33px !important"
  },
  divider: {
    opacity: 0.2,
    background: theme.palette.colors.gray || "#545964"
  },
  listItemText: {
    opacity: 0.7,
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontSize: "16px",
    color: theme.palette.colors.gray || "#e5e2e5"
  },
  closeButton: {
    alignSelf: "flex-start",
    marginLeft: "10px",
    marginTop: "-12px",
    padding: "12px"
  },
  menuBody: {
    height: "94%",
    maxHeight: "850px",
    margin: 0

  },
  usernameStyle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    textAlign: "center",
    color: "#fff",
    lineHeight: "normal",
    maxWidth: "calc(100% - 20px)",
    wordBreak: "break-word"
  },
  roleStyle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    color: alpha("#fff", 0.7),
    lineHeight: "normal"
  },
  listItemStyle: {
    display: "flex",
    justifyContent: "space-between",
    justifyItems: "center",
    paddingLeft: "3px",
    paddingRight: "14px",
    height: "54px"
  },
  iconTextContainer: {
    display: "inline-flex",
    justifyItems: "center",
    justifyContent: "center",
    paddingLeft: "20px"
  },
  menuIcon: {
    display: "flex",
    width: "30px",
    height: "30px",
    margin: "3px 10px 3px 0px",
    fill: alpha("#AAA2AA", 0.8)
  },
  addMarginIOS: {
    marginTop: "15px"
  },
  menuItemSelected: {
    background: theme.palette.colors.lightGray || "#e5e2e5"
  }
}));
