import { makeStyles } from '@material-ui/core/styles';

const aboutUsStyle = makeStyles(() => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '90%',
  },
  container: {
    height: '100%',
    padding: '26px 17px 0px 26px',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },

  subHeaders: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#545964',
  },

  subtitles: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#545964',
  },
  buttonBlock: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  forwardArrow: {
    fontSize: 'medium',
    color: '#545964',
    marginLeft: '5px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  redItemName: {
    color: '#f05347',
  },
  description: {
    fontSize: '16px',
    marginTop: '10px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: '50px',
  },
  deleteInput: {
    width: '100%',
    margin: '5px',
  },
  cancelButtonStyle: {
    width: '100%',
    textTransform: 'capitalize',
    borderRadius: '8px',
    margin: '5px',
  },
  confirmButtonStyle: {
    width: '100%',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: '8px',
    margin: '5px',
  },
}));

export default aboutUsStyle;
