import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  dialogContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
  },
  content: {
    padding: 20,
    paddingBottom: 20,
    overflowY: 'auto',
  },
  buttonsContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    fontSize: '16px',
    marginTop: '10px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
  },
  deleteInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelButtonStyle: {
    width: '100%',
    textTransform: 'capitalize',
    borderRadius: '8px',
    margin: '5px',
  },
  confirmButtonStyle: {
    width: '100%',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: '8px',
    margin: '5px',
  },
  deleteNameInput: {
    width: '100%',
    margin: '5px',
  },
  redItemName: {
    color: '#f05347',
  },
}));

export default useStyles;
