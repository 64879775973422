import { alpha } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    grid: {
      maxWidth: "768px",
      alignItems: "center",
      justifyContent: "flex-start",
      flexFlow: "column nowrap"
    },
    changePasswordFeild: {
      border: "none"
    },
    list: {
      width: "90%",
      "& li": {
        height: "50px",
        margin: "auto",
        marginBottom: "15px",
        borderRadius: "6px"
      },
      "& input": {
        width: "100%",
        paddingLeft: "17px",
        fontSize: "16px",
        fontWeight: "normal"
      },
      "& span": {
        fontFamily: "Roboto",
        fontWeight: "normal"
      }
    },
    paper: {
      padding: "5%",
      height: "100%"
    },
    form: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box",
      flex: 1
    },
    inputFieldStyle: {
      marginTop: 0,
      marginBottom: "21px",
      width: "100%"
    },
    inputClass: {
      height: "25px",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "bold",
      borderRadius: "6px"
    },
    saveButton: {
      height: "50px",
      width: "100%",
      marginTop: "auto",
      "& label": {
        fontSize: "18px"
      }
    },
    errorStyle: {
      fontSize: "12px"
    },
    iconStyle: {
      fontSize: "22px"
    },
    visibilityStyle: {
      fontSize: "14px",
      marginLeft: "-15px",
      zIndex: 100
    },
    forwardArrow: {
      fontSize: "medium"
    },

    addSpace: {
      marginTop: "5px"
    },
    itemContainer: {
      width: "58%",
      flexFlow: "column",
      justifyContent: "center"
    },
    emailInput: { width: "100%" },
    dialogStyle: {
      height: "100%",
      alignItems: "center",
      maxWidth: "768px",
      maxHeight: "850px"
    },
    profilePictureInDialog: {
      width: "100%",
      height: "30%",
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    resetPasswordDialogMessage: {
      width: "60%",
      fontSize: "16px",
      fontWeight: "normal",
      textAlign: "center",
      marginBottom: "30px",
      lineHeight: "normal"
    },
    inputFeildLabel: {
      top: "12px",
      left: "4px",
      fontSize: "12px",
      position: "relative",
      transform: "unset"
    },
    selectContainer: {
      marginBottom: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "100%"
    },
    switchTitle: {
      fontSize: "16px"
    },
    switchContainer: {
      "& input": {
        width: "300%"
      },
      height: 49
    },
    resetPasswordTitleStyle: {
      paddingLeft: "8px"
    },
    itemTextStyle: {
    },
    phoneInput: {
      fontSize: "14px",
      fontWeight: "normal",
      "& ::placeholder": {
        opacity: 1
      },
      "& input": {
        backgroundColor: "transparent",
        border: "none",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal"
      }
    },
    avatar: { marginTop: "30px", marginBottom: "23px" },
    saveButtonStyle: {
      marginBottom: "21px",
      width: "100%",
      padding: "25px 0"
    },
    saveButtonContainer: {
      minHeight: "85px",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "flex-end"
    },
    labelStyle: {
      alignSelf: "flex-start",
      marginBottom: "6px",
      fontSize: "14px"
    },
    fieldsContainer: { width: "70%" },
    usernameErrorStyle: {
      padding: 0,
      marginTop: "-11px",
      paddingBottom: "10px",
      fontSize: "12px"
    },
    capitalize: {
      textTransform: "capitalize"
    },
    floatingPlaceHolder: {
      position: "absolute",
      top: "-7px",
      padding: "0 3px",
      fontSize: "12px",
      borderRadius: "5px"
    },
    textFieldInputClass: {
      "& input": {
        padding: "15px "
      },
      width: "100%",
      fontSize: "16px",
      marginBottom: "15px",
      "& ::placeholder": {
        opacity: 0.5,
        fontSize: "16px",
        lineHieght: "normal",
        fontWeight: "normal"
      }
    },
    inputLabel: {
      top: "-5px",
      left: "15px"
    },
    input: {
      textAlign: "left",
      height: "49px",
      width: "100%",
      "&>svg": {
        width: "25px",
        height: "25px"
      }
    },
    buttonBlock: {
      background: theme.palette.background.default,
      padding: "15px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "15px"
    },
    menuItemsContainer: {
      maxHeight: "200px",
      transitionDuration: "0s !important"
    },
  
    title: {
      fontSize: '16px',
      fontWeight: 'bold',
      margin: '10px'
    },
    description: {
      fontSize: '16px',
      margin: '10px'
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 'auto',
      marginBottom: '50px'
    },
    deleteInfoBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cancelButtonStyle: {
      width: '80%',
      textTransform: 'capitalize',
      borderRadius: '8px',
      margin: '5px'
    },
    confirmButtonStyle: {
      width: '80%',
      boxShadow: 'none',
      textTransform: 'capitalize',
      borderRadius: '8px',
      margin: '5px'
    },
    deleteNameInput: {
      width: '80%',
      margin: '5px'
    },
    redItemName: {
      color: "#f05347"
    }
  });
