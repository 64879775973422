import { Link, Typography, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState, useMemo, useCallback } from 'react';
import { t } from 'ttag';
import { TopBar } from '@components';
import { ArrowBack } from '../../svgComponents';
import useStyles from './styles';
import { ArrowForwardIos } from '@material-ui/icons';
import Button from '@cool_widgets/Button';
import { useStoreActions } from '@models/RootStore';

const DataAndPrivacy = ({ history }) => {
  const classes = useStyles();

  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);

  const addMessage = useStoreActions(
    (action) => action.errorMessage.addMessage
  );
  const deleteMe = useStoreActions((action) => action.deleteMe);

  const handleDeleteMe = async () => {
    try {
      await deleteMe();
      history.push('/');
    } catch (error) {
      addMessage({
        message: error.message,
      });
    }
  };

  const [confirmationInput, setConfirmationInput] = useState('');

  const isDeleteDisabled = useMemo(
    () => confirmationInput.toLowerCase() !== 'delete',
    [confirmationInput]
  );

  const handleInputChange = useCallback(({ target }) => {
    setConfirmationInput(target.value);
  }, []);

  const goBack = () => {
    isDeleteAccountOpen
      ? setIsDeleteAccountOpen(false)
      : history.push({ pathname: '/profile', menuOpen: true });
  };

  return (
    <div className={classes.screenContainer}>
      <TopBar
        title={
          isDeleteAccountOpen
            ? t`Delete my account`
            : t`Data & Privacy`
        }
        leftIconComponent={<ArrowBack />}
        leftAction={goBack}
      />

      {!isDeleteAccountOpen ? (
        <div className={classes.container}>
          <>
            <Typography
              className={classes.subHeaders}
            >{t`Privacy Policy`}</Typography>

            <Link
              target="_blank"
              href={'https://coolautomation.com/privacy-policy/'}
              className={classes.subtitles}
            >
              {'https://coolautomation.com/privacy-policy/'}
            </Link>
          </>

          <>
            <div
              className={classes.buttonBlock}
              onClick={() => setIsDeleteAccountOpen(true)}
            >
              <Typography
                className={classes.subHeaders}
              >{t`Delete User Account and All Data`}</Typography>
              <ArrowForwardIos className={classes.forwardArrow} />
            </div>
          </>
        </div>
      ) : (
        <div className={classes.container}>
          <div>
            <Typography className={clsx(classes.title, classes.redItemName)}>
              {t`Warning: Removal of account and data is irreversible`}
            </Typography>
            <Typography className={classes.description}>
              <div>{t`To Delete your user account and all Data, please approve the action by explicitly typing "Delete" in the box below and then click the "Delete Account & Data" button.`}</div>
              <br />
              <div>{t`To exit without deletion, click the "Cancel" button`}</div>
            </Typography>
          </div>

          <div className={classes.actionsContainer}>
            <TextField
              variant={'outlined'}
              placeholder={'Delete'}
              value={confirmationInput}
              className={classes.deleteInput}
              onChange={handleInputChange}
            />

            <Button
              disabled={isDeleteDisabled}
              onClick={handleDeleteMe}
              className={classes.confirmButtonStyle}
              variant="contained"
              red
            >
              {t`Delete User account and Data`}
            </Button>
            <Button
              onClick={() => setIsDeleteAccountOpen(false)}
              className={classes.cancelButtonStyle}
              variant="outlined"
              white
            >
              {t`Cancel`}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataAndPrivacy;
