import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  view: {
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
  },
  contentArea: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'rgb(236, 235, 239)'
  },
  mainContainer:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    padding: '10px'
  },
  iframeContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    overflow: 'hidden',
  },
  iframe: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    border: 'none',
  },
  select: {
    width: '100%',
    padding: '12px 14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  option: {
    padding: '12px',
    fontSize: '14px',
  },
}));

export default useStyles;
