import React, { useCallback, useMemo, useState } from 'react';
import { Dialog, Typography, IconButton, TextField } from '@material-ui/core';
import { Close } from '@icons';
import clsx from 'clsx';
import { t } from 'ttag';
import PropTypes from 'prop-types';
import { useStoreActions } from '@models/RootStore';
import Button from '@cool_widgets/Button';
import useStyles from './styles';

const DeleteAccountsDialog = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const [confirmationInput, setConfirmationInput] = useState('');
  const deleteMe = useStoreActions((action) => action.deleteMe);
  const addMessage = useStoreActions(
    (action) => action.errorMessage.addMessage
  );

  const handleDeleteMe = async () => {
    try {
      await deleteMe();
    } catch (error) {
      addMessage({
        message: error.message,
      });
    }
  };

  const isDeleteDisabled = useMemo(
    () => confirmationInput.toLowerCase() !== 'delete',
    [confirmationInput]
  );

  const handleInputChange = useCallback(({ target }) => {
    setConfirmationInput(target.value);
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <div className={classes.dialogHeader}>
        <Typography
          className={classes.headerTitle}
        >{t`Delete my user account and data`}</Typography>
        <IconButton
          disableRipple
          className={classes.iconBtnStyle}
          onClick={onClose}
        >
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <div className={classes.content}>
          <Typography className={clsx(classes.title, classes.redItemName)}>
            {t`Warning: Removal of account and data is irreversible`}
          </Typography>
          <Typography className={classes.description}>
            <div>{t`To Delete your user account and all Data, please approve the action by explicitly typing "Delete" in the box below and then click the "Delete Account & Data" button.`}</div>
            <br />
            <div>{t`To exit without deletion, click the "Cancel" button`}</div>
          </Typography>
        </div>

        <div className={classes.buttonsContainer}>
          <div className={classes.actionsContainer}>
            <TextField
              variant={'outlined'}
              placeholder={'Delete'}
              value={confirmationInput}
              className={classes.deleteNameInput}
              onChange={handleInputChange}
            />
            <Button
              disabled={isDeleteDisabled}
              onClick={handleDeleteMe}
              className={classes.confirmButtonStyle}
              variant="contained"
              red
            >
              {t`Delete User account and Data`}
            </Button>
            <Button
              onClick={onClose}
              className={classes.cancelButtonStyle}
              variant="outlined"
              white
            >
              {t`Cancel`}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

DeleteAccountsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

export default DeleteAccountsDialog;
